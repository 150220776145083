<template>
  <div>
    <!-- 滚动图开始 -->
    <div class="scrollChart">
      <ScrollChart :scrImg="scrImg"></ScrollChart>
    </div>
    <!-- 滚动图结束 -->
    <!-- 内容开始 -->
    <div class="contentbotton">
      <!-- 左侧菜单开始 -->
      <menuLeft @gettype="handleSelect" :formdata="formdata"></menuLeft>
      <!-- <div class="left">
        <div class="leftbig">
          <div class="leftTop">旭德教育</div>
          <div class="menu">
            <el-menu
              style="border-right: 0px"
              :default-active="
                '/' +
                $route.path.split('/')[1] +
                '/' +
                $route.path.split('/')[2]
              "
              @select="handleSelect"
            >
              <el-menu-item :index="'/volunterservce/Comprehensive'">
                <i class="el-icon-menu"></i>
                <span slot="title">综合测评</span>
              </el-menu-item>
              <el-menu-item index="/volunterservce/Intelligentreporting">
                <i class="el-icon-menu"></i>
                <span slot="title">智能填报</span>
              </el-menu-item>
              <el-menu-item index="/volunterservce/oneServe">
                <i class="el-icon-menu"></i>
                <span slot="title">1对1服务</span>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="applet">
            <img :src="QRcode[0]"  :onerror="smallImgError" alt="小程序" />
          </div>
          <div class="oneToOne" @click="goOneToOne()">
            <img src="@/assets/img/oneToOne.png" alt="1对1服务" />
          </div>
        </div>
      </div> -->
      <!-- 左侧菜单结束 -->
      <!-- 右侧列表开始 -->
      <div class="right">
        <!-- 讲座管理  -->
        <keep-alive include="Intelligentreporting">
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>
    <!-- 内容结束 -->
  </div>
</template>

<script>
import {
  getAdvertisementDetail, //顶部轮播图
} from "@/api";
import ScrollChart from "@/components/scrollChart";
import menuLeft from "@/components/menu";
// import Leftmenu from "./components/leftmenu";
export default {
  name: "volunterservce",
  components: {
    ScrollChart,
    menuLeft
    // Leftmenu,
  },
  data() {
    return {
      index: this.$route.path,
      type: "1",
      videolist: [], //网课列表
      scrImg: [], //轮播图
      QRcode: [], //小程序图片
      formdata: {
        topText: "志愿服务",
        menulist: [
          { path: "/volunterservce/Comprehensive", title: "职业测评" },
          { path: "/volunterservce/Intelligentreporting", title: "智能填报" },
          { path: "/volunterservce/oneServe", title: "1对1服务" },
        ],
      },
    };
  },
  mounted() {
    // 首页轮播图
    this.getTop();
    //小程序图片
    this.getQRcode();
  },
  methods: {
    goOneToOne(){
      this.$router.push("/volunterservce/oneServe");
    },
    // 顶部轮播图片
    async getTop() {
      let res = await getAdvertisementDetail({ id: 3 });
      if (res.code == 1) {
        console.log(res, "333333333");
        this.scrImg = res.data.images || "";
      }
    },
    // 小程序图片
    async getQRcode() {
      let res = await getAdvertisementDetail({ id: 8 });
      if (res.code == 1) {
        this.QRcode = res.data.images || "";
      }
    },
    handleSelect(type) {
      this.type = type;
      if (type == "/volunterservce/Comprehensive") {
        // 综合评测
        this.$router.push("/volunterservce/Comprehensive");
      }
      if (type == "/volunterservce/Intelligentreporting") {
        // 智能填报
        this.$router.push("/volunterservce/Intelligentreporting");
      }
      if (type == "/volunterservce/oneServe") {
        // 一对一服务
        this.$router.push("/volunterservce/oneServe");
      }

      if (type == "/volunterservce/contactus") {
        // 联系我们
        this.$router.push("/information/contactus");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.scrollChart {
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.leftbig {
  width: 260px;
  .leftTop {
    background: url(../../assets/img/leftmune_top_bg.jpg) center top no-repeat;
    background-position: 100% auto;
    height: 106px;
    text-align: center;
    line-height: 106px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
  }
  .menu {
    width: 100%;
  }
  .applet {
      width: 100%;
      img {
        width: 160px;
        margin-left: 50px;
      }
    }
}
.right {
  //   height: 730px;
  //   overflow: auto;
  margin-left: 30px;
  width: 100%;
  .title {
    width: 960px;
    height: 106px;
    font-size: 26px;
    color: #222222;
    line-height: 106px;
    text-align: center;
  }
  .rightcontent {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    .videodiv {
      width: 220px;
      margin: 25px 40px;
      height: 170px;
      background-color: #e6e6e6;
    }
  }
}
.scrollChart {
  margin-bottom: 60px;
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.contentbotton {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
}
/deep/.el-menu-item {
  font-size: 16px !important;
}
/deep/.el-menu-item.is-active {
  color: #1ab394 !important;
}
/deep/.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color: #fff;
  color: #1ab394 !important;
}
.oneToOne:hover{
  img{
    transform: scale(1.2);
  }
}
</style>
